import type { FC } from 'react';
import React from 'react';

import type { BaseComponentProps } from '../../../../types';
import MobileViewWidget from './MobileViewWidget';

const MobileWidget: FC<BaseComponentProps> = (props) => (
  <MobileViewWidget {...props} />
);

export default MobileWidget;
